import React, { Component } from "react"
import { graphql } from "gatsby"
import { Container } from "semantic-ui-react"

class Page extends Component {
  render() {
    const StaticPage = this.props.data.page
    return (
      <>
        <Container>
          <h1>{StaticPage.title}</h1>
          <div>{StaticPage.content}</div>
        </Container>
      </>
    )
  }
}

export default Page

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`
